import { isAdvertiser, isReadOnly } from '~/helpers'
import { statusType } from '~/modules/retailMedia/components/StatusBar/statusType'

import { formatAds } from '../ads'
import { formatNumberByType } from '../helpers'
import { formatMetrics } from '../metrics'
import { formatIntervalDates } from './format'

const formatBanners = (campaign: CampaignViewResponse) => ({
  keywords: campaign.ads?.[0]?.settings?.keywords,
  categories: campaign.ads?.[0]?.settings?.categories,
  destinationUrls: campaign.ads?.[0]?.settings?.destination_urls,
  destinationUrl: campaign.ads?.[0]?.url,
  data:
    campaign.ads?.[0]?.settings?.assets?.map(
      (ad: IndustryCampaignViewResponseAdAsset) => ({
        media_url: ad?.media_url,
        media_size: ad?.media_size,
        metrics: {
          ecpm: {
            value: campaign.ads?.[0]?.metrics?.ecpm
          },
          prints: {
            value: formatNumberByType({
              type: 'unit',
              value: campaign.ads?.[0]?.metrics?.impressions
            })
          },
          clicks: {
            value: formatNumberByType({
              type: 'unit',
              value: campaign.ads?.[0]?.metrics?.clicks
            })
          },
          income: {
            value: formatNumberByType({
              type: 'money',
              value: campaign.ads?.[0]?.metrics?.sales_total
            })
          },
          conversion: {
            value: formatNumberByType({
              type: 'percent',
              value: campaign.ads?.[0]?.metrics?.conversions
            })
          }
        }
      })
    ) || null
})

export const formatViewCampaign = (
  campaign: CampaignViewResponse
): CampaignView => {
  //

  return {
    // config
    canEditCampaign:
      statusType?.[campaign.status]?.canEditDate &&
      isAdvertiser &&
      !isReadOnly(),
    // raw
    raw: { ...campaign },
    // formattedCampaign
    id: campaign.id,
    audienceId: campaign.audience_id,
    targetingType: campaign?.targeting_type,
    advertiserId: campaign.advertiser_id,
    active: campaign.active,
    title: campaign.name,
    description: campaign?.description,
    ...formatIntervalDates({
      start_at: campaign.start_at,
      end_at: campaign.end_at,
      ignoreUTC: true
    }),
    adType: campaign.ad_type,
    type: campaign.type,
    status: campaign.status,
    settings: {
      ...campaign.settings,
      budget_type: campaign?.settings?.budget_type || 'fast_as_possible'
    },
    advertiser: {
      id: campaign?.advertiser_id,
      accountId: campaign?.advertiser_account_id,
      name: campaign?.advertiser_name,
      logo: campaign?.advertiser_account_logo,
      accountTheme: {
        header_color: campaign?.advertiser_account_theme?.header_color,
        primary_color: campaign?.advertiser_account_theme?.primary_color,
        secondary_color: campaign?.advertiser_account_theme?.secondary_color
      }
    },
    publisher: {
      id: campaign?.publisher_id,
      accountId: campaign?.publisher_account_id,
      name: campaign?.publisher_name,
      logo: campaign?.publisher_account_logo,
      accountTheme: {
        header_color: campaign?.publisher_account_theme?.header_color,
        primary_color: campaign?.publisher_account_theme?.primary_color,
        secondary_color: campaign?.publisher_account_theme?.secondary_color
      }
    },
    products: [...campaign.products],
    banners: formatBanners(campaign),

    selectedProducts: campaign.ads.map(
      item =>
        ({
          ...item,
          id: item?.product_id,
          item_disabled: true
        }) as SelectedProduct
    ),
    budget: {
      total: formatNumberByType({
        type: 'money',
        value: campaign?.settings?.daily_budget
      }),
      spent: formatNumberByType({
        type: 'money',
        value: campaign?.consumed_budget || 0
      }),
      raw: {
        dailyBudget: Number(campaign?.settings?.daily_budget),
        dailyConsumed: Number(campaign?.consumed_budget)
      }
    },
    metrics: formatMetrics({ data: campaign.metrics }),
    destinationUrls: campaign.ads?.[0]?.settings?.destinations,
    destinationUrl: campaign.ads?.[0]?.url,
    categories: campaign.ads?.[0]?.settings?.categories,
    keywords: campaign.keywords || campaign.ads?.[0]?.settings?.keywords || [],
    status_history: campaign.status_history,

    ads: {
      itemsPerPage: 50,
      total: campaign.ads.length,
      data: formatAds({ ads: campaign.ads, campaignStatus: campaign.status })
    }
  }
}
