import moment from 'moment'

import {
  GetDurationInDaysProps,
  formatDateToString,
  formatDateToStringIgnoreUTC,
  getDurationInDays
} from '~/helpers'
import i18n from '~/i18n'
import { retailMediacampaignTexts } from '~/modules/retailMedia/i18n/pt-br'

const getLabelAdType = (adType: CampaignAdType) =>
  retailMediacampaignTexts.ad_types?.[adType] || adType

const formatIntervalDates = ({
  start_at,
  end_at,
  ignoreUTC = false
}: FormatIntervalDatesProps) => {
  const formatDate = ignoreUTC
    ? formatDateToStringIgnoreUTC
    : formatDateToString

  const startDate = formatDate(start_at, i18n.t('c:patterns.date.short'))
  const startDateWithHours = formatDate(
    start_at,
    i18n.t('c:patterns.dateTimeAt')
  )

  const endDate = end_at ? formatDate(end_at, 'DD/MM/YY') : null
  let endDateEdited = moment(end_at)

  function adjustEndDate(endDateWithHours: string) {
    if (endDateWithHours.endsWith('00:00')) {
      endDateEdited = moment(end_at).add('1349', 'minutes')
      return endDateWithHours.replace('00:00', '23:59')
    }
    return endDateWithHours
  }

  const endDateWithHours = end_at
    ? adjustEndDate(formatDate(end_at, i18n.t('c:patterns.dateTimeAt')))
    : null

  const interval = endDate ? `${startDate} até ${endDate}` : startDate

  const duration = getDurationInDays({
    startDate: start_at,
    endDate: end_at
  })

  // Format remaing

  const endDateIsFuture = moment().isBefore(endDateEdited, 'day')
  const endDateIsPast = moment().isAfter(endDateEdited, 'day')

  const remaining: () => string | null = () => {
    if (endDateIsPast) return null

    const props: GetDurationInDaysProps = {
      startDate: new Date(),
      endDate: endDateEdited,
      prefix: [i18n.t('c:words.remain'), i18n.t('c:words.remains')]
    }

    return endDateIsFuture
      ? (getDurationInDays(props) as string)
      : i18n.t('c:words.endsToday')
  }

  return {
    startDate,
    endDate,
    interval,
    duration,
    startDateWithHours,
    endDateWithHours,
    remaining: remaining()
  } as FormatIntervalDates
}

export { getLabelAdType, formatIntervalDates }
