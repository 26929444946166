import { formatMoney } from '~/helpers'

import {
  formatIntervalDates,
  getLabelAdType
} from '../../common/campaign/format'
import { formatMetrics } from '../metrics'

export const formatListCampaign = (data: CampaignWithMetrics[]) => {
  if (!data) return null

  return data.map(campaign => ({
    _raw: {
      originPath: 'src/modules/retailMedia/dtos/campaign/list.ts'
    },
    raw: campaign,
    id: campaign.id,
    audienceId: campaign.audience_id,
    title: campaign.name,
    adType: getLabelAdType(campaign.ad_type),
    type: campaign.type,
    active: campaign.active,
    targetingType: campaign.targeting_type,
    status: campaign.status,
    advertiser: {
      id: campaign?.advertiser_id,
      accounttId: campaign?.advertiser_account_id,
      name: campaign?.advertiser_name,
      logo: campaign?.advertiser_account_logo,
      bgColor: campaign?.advertiser_account_theme?.header_color || '#fff'
    },
    publisher: {
      id: campaign?.publisher_id,
      accounttId: campaign?.publisher_account_id,
      name: campaign?.publisher_name,
      logo: campaign?.publisher_account_logo,
      bgColor: campaign?.publisher_account_theme?.header_color || '#fff'
    },
    ...formatIntervalDates({
      start_at: campaign.start_at,
      end_at: campaign.end_at
    }),
    budget: {
      total: formatMoney(campaign?.settings?.daily_budget),
      spent: formatMoney(campaign?.consumed_budget),
      raw: {
        dailyBudget: Number(campaign?.settings?.daily_budget),
        dailyConsumed: Number(campaign?.consumed_budget)
      }
    },
    metrics: formatMetrics({ data: campaign?.metrics })
  })) as FormatListCampaign[]
}
